import axios from '@axios'
import environment from '@/environment'

export default {
  namespaced: true,
  state: {
    categories: [],
    criteria: [],
    managements: [],
    statuses: [],
    types: [],
    accountTypes: [],
  },
  getters: {
    allAccountTypes: state => state.accountTypes,
    allCategories: state => state.categories,
    allCriteria: state => state.criteria,
    allManagements: state => state.managements,
    allStatuses: state => state.statuses,
    allTypes: state => state.types,
  },
  mutations: {
    SET_ACCOUNT_TYPES(state, accountTypes) {
      state.accountTypes = accountTypes
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_CRITERIA(state, criteria) {
      state.criteria = criteria
    },
    SET_MANAGEMENTS(state, managements) {
      state.managements = managements
    },
    SET_TYPES(state, types) {
      state.types = types
    },
    SET_STATUSES(state, statuses) {
      state.statuses = statuses
    },
  },
  actions: {
    fetchCatalogs({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${environment.uri}/ccenters/catalogs`)
          .then(response => {
            if (response && response.data && response.data) {
              if (response.data.criterios) {
                commit('SET_CRITERIA', response.data.criterios.sort((a, b) => ((a.criterio > b.criterio) ? 1 : -1)))
              }
              if (response.data.gerencias) {
                commit('SET_MANAGEMENTS', response.data.gerencias.sort((a, b) => ((a.gerencias > b.gerencias) ? 1 : -1)))
              }
              if (response.data.estados) {
                commit('SET_STATUSES', response.data.estados.sort((a, b) => ((a.status > b.status) ? 1 : -1)))
              }
              if (response.data.tipos) {
                commit('SET_TYPES', response.data.tipos.sort((a, b) => ((a.tipo > b.tipo) ? 1 : -1)))
              }
              if (response.data.categorias) {
                commit('SET_CATEGORIES', response.data.categorias.sort((a, b) => ((a.categoria > b.categoria) ? 1 : -1)))
              }
              if (response.data.tiposCuenta) {
                commit('SET_ACCOUNT_TYPES', response.data.tiposCuenta.sort((a, b) => ((a.tipo > b.tipo) ? 1 : -1)))
              }
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
