export const initialAbility = [
  {
    action: 'read',
    subject: 'auth',
  },
]

export const abilities = {
  master: [
    {
      action: 'read',
      subject: 'dashboard',
    },
    {
      action: 'manage',
      subject: 'all',
    },
  ],
  administrator: [
    {
      action: 'manage',
      subject: 'all',
    },
  ],
  manager: [
    {
      action: 'read',
      subject: 'dashboard',
    },
    {
      action: 'read',
      subject: 'administrative-management',
    },
    {
      action: 'read',
      subject: 'cost-center-type',
    },
    {
      action: 'read',
      subject: 'cost-center',
    },
    {
      action: 'read',
      subject: 'accounting-account-type',
    },
    {
      action: 'read',
      subject: 'accounting-account-category',
    },
    {
      action: 'read',
      subject: 'accounting-account-assistant',
    },
    {
      action: 'read',
      subject: 'mortgage-loan-range',
    },
    {
      action: 'read',
      subject: 'branch-profitability-movements-report',
    },
    {
      action: 'read',
      subject: 'branch-financial-rentability-report',
    },
    {
      action: 'read',
      subject: 'branch-redistribution-report',
    },
    {
      action: 'read',
      subject: 'branch-aux-report',
    },
    {
      action: 'manage',
      subject: 'all',
    },
  ],
  reader: [
    {
      action: 'read',
      subject: 'dashboard',
    },
/*    {
      action: 'manage',
      subject: 'branch-redistribution-report',
    },
    {
      action: 'manage',
      subject: 'branch-financial-rentability-report',
    },*/
  ],
  approval: [
    {
      action: 'read',
      subject: 'dashboard',
    },
    {
      action: 'read',
      subject: 'branch-aux-report',
    },
    {
      action: 'manage',
      subject: 'auxiliary-approval',
    },
  ],
  leader: [
    {
      action: 'read',
      subject: 'dashboard',
    },
    {
      action: 'manage',
      subject: 'accounting-account-assistant',
    },
    {
      action: 'manage',
      subject: 'accounting-account',
    },
/*    {
      action: 'read',
      subject: 'branch-financial-rentability-report',
    },
    {
      action: 'read',
      subject: 'branch-redistribution-report',
    },*/
    {
      action: 'read',
      subject: 'branch-aux-report',
    },
  ],
}
