import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  cdaCenterTypes: [
    {
      id: 1,
      type: 'Autobanco',
    },
    {
      id: 2,
      type: 'Cartera',
    },
    {
      id: 3,
      type: 'Centro Operativo',
    },
    {
      id: 4,
      type: 'Cerrado',
    },
    {
      id: 5,
      type: 'Departamento',
    },
    {
      id: 6,
      type: 'Gerencia',
    },
  ],
  cdaAdministrativeManagement: [
    {
      id: 1,
      primaryCenter: 324,
      directiveManagement: 'Admin Crédito',
      businessRule: 1,
      incomeGenerator: false,
      spendingDistPercentage: 20,
      incomeDistPercentage: 0,
    },
    {
      id: 2,
      primaryCenter: 563,
      directiveManagement: 'Auditoria',
      businessRule: 2,
      incomeGenerator: false,
      spendingDistPercentage: 15,
      incomeDistPercentage: 0,
    },
    {
      id: 1,
      primaryCenter: 46,
      directiveManagement: 'Cobros',
      businessRule: 1,
      incomeGenerator: true,
      spendingDistPercentage: 10,
      incomeDistPercentage: 0,
    },
  ],
  /* eslint-disable global-require */
}
mock.onGet('/cda/cdaCentersType').reply(() => [200, data.cdaCenterTypes])
mock.onGet('/cda/cdaAdministrativeManagement').reply(() => [200, data.cdaAdministrativeManagement])
