import Vue from 'vue'
import {ToastPlugin, ModalPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import dayjs from 'dayjs'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'


// Global Components
import './global-components'
// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.filter('date', (value, format) => {
    if (!value) {
        return ''
    }

    format = format || 'MMM DD, YYYY - HH:mm'
    value = value.substring(0, value.length -2)
    try {
        return dayjs(value).format(format)
    } catch (error) {
        console.error('date parser error: ', error)
        return ''
    }
})
Vue.filter('currency', value => {
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }

    if (Number.isNaN(value) || value == null) {
        return `${parseFloat(0).toFixed(2)}`
    }

    return `${Number(value).toLocaleString('en', options)}`
})
new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
